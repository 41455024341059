<template>
  <div class="signup">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.signup
  position: relative
  z-index: 2
</style>
